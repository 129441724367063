<template>
  <content-wrapper :title="labels.LBL_REPLY" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('replyForm')">
        <i class="el-icon-s-promotion mr-1"></i> {{ labels.LBL_SEND }}
      </el-button>
    </template>

    <div v-loading="loading">
      <div class="mb-3">
        <b>{{ labels.LBL_MESSAGE }}: </b>
        <div class="text-card">
          {{ detail.message }}
        </div>
      </div>

      <div>
        <p>
          To: <b>{{ detail.name }}</b> <span>({{ detail.email }})</span>
        </p>
        <p v-if="detail.phone">
          {{ labels.LBL_CONTACT_NO }}: {{ detail.phone }}
        </p>
        <p v-if="detail.address">
          {{ labels.LBL_ADDRESS }}: {{ detail.address }}
        </p>
        <p v-if="detail.interested_in">
          {{ labels.LBL_INTERESTED_IN }}:
          <span class="primary-text"> {{ detail.interested_in }}</span>
        </p>
      </div>

      <br />

      <el-form
        ref="replyForm"
        :model="message"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="reply" :label="labels.LBL_REPLY">
              <RichEditor v-model="message.reply"></RichEditor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import RichEditor from "@/components/RichEditor";

  export default {
    name: "Reply",

    props: {
      replyTo: {
        type: [String, Number],
        default: "",
      },
    },

    components: {
      ContentWrapper,
      RichEditor,
    },

    data() {
      return {
        labels,
        rules,

        message: {
          reply: "",
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        detail: (state) => state.feedback.feedbackDetail,
      }),
    },

    watch: {
      replyTo: {
        immediate: true,
        handler(nv) {
          this.loadMessageDetail(nv);
        },
      },
    },

    methods: {
      loadMessageDetail(id) {
        this.$store.dispatch("feedback/getMessageDetail", id);
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const reply = { ...this.detail, reply_message: this.message.reply };
            this.$store.dispatch("feedback/replyFeedback", reply);
          }
        });
      },
    },
  };
</script>

<style></style>
